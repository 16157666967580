<template>
    <div class="tag-box" :style="{ color: meetingTagColors[String(status)] || '#909399' }">
        {{ statusTxt }}
    </div>
</template>

<script>
export default {
    props: {
        status: [String, Number],
        statusTxt: String,
        isTag: {
            type: Boolean,
            default: () => {
                return false
            }
        }
    },
    data() {
        return {
            meetingTagColors: {
                10: "#a67c0b", //待接单
                50: "#a67c0b", //会议执行
                100: "#4ac672", //已完成，
                200: "#a67c0b", //会议执行
            },
        };
    },
    created() {},
    methods: {},
};
</script>
<style>
.create-box .van-cell::after {
    display: none;
}
</style>

<style lang="less" scoped>
.tag-box {
    right: 0.15rem;
    top: 0.15rem;
    height: 0.2rem;
    font-size: 0.13rem;
    position: absolute;
}
</style>
